<template>
  <section class="main">
    <div class="content">
      <a-result title="请在移动端使用答题竞赛功能" />
    </div>
  </section>
</template>

<style lang="less" scoped>
.main {
  height: calc(100vh - 246px);
  padding: 24px 0;
  .content {
    margin: 0 auto;
    width: 1200px;
    height: 100%;
    background-color: #fff;
    .mixinFlex(center; center);
  }
}
</style>
